import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { MetricsRequestPayload, MetricsResponse } from "./metrics-types";

interface Company {
    id: string;
    name: string;
    location: string;
    status: string;
    createdAt: string;
}

interface Report {
    id: string;
    companyId: number;
    name: string;
    description: string;
    startDate: string;
    endDate: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    isActive: boolean;
}

interface MetricsState {
    metrics: any;
    companies: Company[];
    companyReports: Report[];
    selectedCompany: Company | null;
    getMetrics: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    getCompanies: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    getCompanyReports: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    saveReport: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    updateReport: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    deleteReport: {
        status: "idle" | "pending" | "fulfilled" | "error";
        errorMessage: string;
    };
    tempDateRange: { startDate: string; endDate: string } | null;
}

// Define the initial state for this slice of the Redux store
const initialState: MetricsState = {
    metrics: null,
    companies: [],
    companyReports: [],
    selectedCompany: null,
    getMetrics: {
        status: "idle",      // API call status: 'idle' | 'pending' | 'fulfilled' | 'error'
        errorMessage: "",    // Error message if API call fails
    },
    getCompanies: {
        status: "idle",
        errorMessage: "",
    },
    getCompanyReports: {
        status: "idle",
        errorMessage: "",
    },
    saveReport: {
        status: "idle",
        errorMessage: "",
    },
    updateReport: {
        status: "idle",
        errorMessage: "",
    },
    deleteReport: {
        status: "idle",
        errorMessage: "",
    },
    tempDateRange: null,
};

// Create an async thunk - this handles the API call
// createAsyncThunk automatically creates pending, fulfilled, and rejected action types
export const getMetrics = createAsyncThunk(
    "metrics/getMetrics",
    // Async function that makes the API call
    async (payload: MetricsRequestPayload, { fulfillWithValue, rejectWithValue }) => {
        try {
            // Make POST request to the metrics endpoint
            const res = await axios.post(
                `${process.env.REACT_APP_API_END_POINT}/Metrics/GetMetrics`,
                payload
            );
            return fulfillWithValue(res.data); // Return successful response
        } catch (error: any) {
            try {
                // Return error message from API if available
                return rejectWithValue(error.response.data.message);
            } catch (e) {
                // Return generic error message if API error message not available
                return rejectWithValue("An error occurred");
            }
        }
    }
);

export const getCompanies = createAsyncThunk(
    "metrics/getCompanies",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/Companies/GetAllCompanies`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Failed to fetch companies");
        }
    }
);

export const getCompanyReports = createAsyncThunk(
    "metrics/getCompanyReports",
    async (companyId: string, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/metrics/GetCompanyReports/${companyId}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Failed to fetch company reports");
        }
    }
);

export const saveReport = createAsyncThunk(
    "metrics/saveReport",
    async (report: Omit<Report, "id" | "createdBy" | "createdAt" | "updatedBy" | "updatedAt" | "isActive">, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_END_POINT}/metrics/SaveCompanyReport`, report);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Failed to save report");
        }
    }
);

export const updateReport = createAsyncThunk(
    "metrics/updateReport",
    async (reportData: Partial<Report>, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_END_POINT}/metrics/UpdateCompanyReport`, { ...reportData, });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Failed to update report");
        }
    }
);

export const deleteReport = createAsyncThunk(
    "metrics/deleteReport",
    async (reportId: string, { rejectWithValue }) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_END_POINT}/metrics/DeleteCompanyReport/${reportId}`);
            return reportId;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Failed to delete report");
        }
    }
);

export const MetricsSlice = createSlice({
    name: "metrics",
    initialState,
    reducers: {
        resetMetrics: (state) => {
            state.metrics = null;
            state.getMetrics = {
                status: "idle",
                errorMessage: "",
            };
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        setTempDateRange: (state, action) => {
            state.tempDateRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMetrics.pending, (state) => {
                state.getMetrics.status = "pending";
                state.getMetrics.errorMessage = "";
            })
            .addCase(getMetrics.fulfilled, (state, action) => {
                state.getMetrics.status = "fulfilled";
                state.metrics = action.payload;
            })
            .addCase(getMetrics.rejected, (state, action) => {
                state.getMetrics.status = "error";
                state.getMetrics.errorMessage = action.payload as string;
            })
            .addCase(getCompanies.pending, (state) => {
                state.getCompanies.status = "pending";
                state.getCompanies.errorMessage = "";
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.getCompanies.status = "fulfilled";
                state.companies = action.payload;
            })
            .addCase(getCompanies.rejected, (state, action) => {
                state.getCompanies.status = "error";
                state.getCompanies.errorMessage = action.payload as string;
            })
            .addCase(getCompanyReports.pending, (state) => {
                state.getCompanyReports.status = "pending";
                state.getCompanyReports.errorMessage = "";
            })
            .addCase(getCompanyReports.fulfilled, (state, action) => {
                state.getCompanyReports.status = "fulfilled";
                state.companyReports = action.payload;
            })
            .addCase(getCompanyReports.rejected, (state, action) => {
                state.getCompanyReports.status = "error";
                state.getCompanyReports.errorMessage = action.payload as string;
            })
            .addCase(saveReport.pending, (state) => {
                state.saveReport.status = "pending";
                state.saveReport.errorMessage = "";
            })
            .addCase(saveReport.fulfilled, (state, action) => {
                state.saveReport.status = "fulfilled";
                state.companyReports.push(action.payload);
            })
            .addCase(saveReport.rejected, (state, action) => {
                state.saveReport.status = "error";
                state.saveReport.errorMessage = action.payload as string;
            })
            .addCase(updateReport.pending, (state) => {
                state.updateReport.status = "pending";
                state.updateReport.errorMessage = "";
            })
            .addCase(updateReport.fulfilled, (state, action) => {
                state.updateReport.status = "fulfilled";
                const index = state.companyReports.findIndex(report => report.id === action.payload.id);
                if (index !== -1) {
                    state.companyReports[index] = action.payload;
                }
            })
            .addCase(updateReport.rejected, (state, action) => {
                state.updateReport.status = "error";
                state.updateReport.errorMessage = action.payload as string;
            })
            .addCase(deleteReport.pending, (state) => {
                state.deleteReport.status = "pending";
                state.deleteReport.errorMessage = "";
            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                state.deleteReport.status = "fulfilled";
                state.companyReports = state.companyReports.filter(report => report.id !== action.payload);
            })
            .addCase(deleteReport.rejected, (state, action) => {
                state.deleteReport.status = "error";
                state.deleteReport.errorMessage = action.payload as string;
            });
    },
});

export const { resetMetrics, setSelectedCompany, setTempDateRange } = MetricsSlice.actions;

export default MetricsSlice.reducer;
