// TODO - Type 'any' needs to be fixed.
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  ASSIGN_SHIFT,
  LOCATION,
  PRIMARYJOB,
  SENIORITY,
  WORKCENTER,
} from "../../../../../../assets/constants";
import MultiLineTooltip from "../../../../../../utils/multi-line-tooltip";
import Highlight from "../../../../../Highlight";
import ShiftStatus from "../ShiftStatus";

const useShiftDetailsDialogColumns = (
  shiftMetadata: any,
  numberOfWorkers: number,
  workerAvailableForAssign: number,
  search: string,
  activeStep: string,
  shiftAllocationType: string,
  isOpenForOtherLocation: boolean
) => {
  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    try {
      const workersColumn = {
        field: "workerName",
        headerName: "Worker",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            {/* <Typography variant="subtitle2">{`Worker (${
              activeStep === ASSIGN_SHIFT
                ? workerAvailableForAssign
                : numberOfWorkers
            })`}</Typography> */}
            <Typography variant="subtitle2">{"Worker"}</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            {params.row.errorMessage !== "" && activeStep === ASSIGN_SHIFT && (
              <Tooltip placement="right" title={params.row.errorMessage}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginRight: "8px",
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#E44B48",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </Box>
              </Tooltip>
            )}
            <Tooltip title={params.row.workerName}>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                <Highlight value={params.row.workerName} searchText={search} />
              </Typography>
            </Tooltip>
          </Box>
        ),
        minWidth: 180,
        flex: 1,
        checked: true,
        label: "Worker",
      };

      const workerTypeColumn = {
        field: "workerTypeText",
        headerName: "Type",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">Type</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box>
            <Typography
              sx={{
                flexWrap: "wrap",
                wordBreak: "break-word",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {params.row.workerTypeText}
            </Typography>
          </Box>
        ),
        minWidth: 100,
        flex: 1,
        checked: true,
        label: "Type",
      };

      const locationsColumn = {
        field: "location",
        headerName: "Location",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">Location</Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <Box>
            <Typography
              sx={{
                flexWrap: "wrap",
                wordBreak: "break-word",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {params.row.location}
            </Typography>
          </Box>
        ),
        minWidth: 130,
        flex: 1,
        checked:
          isOpenForOtherLocation ||
          shiftAllocationType === WORKCENTER ||
          shiftAllocationType === LOCATION,
        label: "Location",
      };

      const shiftTimeColumn = {
        field: "fullTimeShifts",
        headerName: "Shift Pattern (Schedule)",
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <Typography variant="subtitle2">
              Shift Pattern (Schedule)
            </Typography>
          </Box>
        ),
        renderCell: (params: any) => (
          <MultiLineTooltip
            text={params?.row?.fullTimeShifts ?? "-"}
            searchText={search}
          />
        ),
        minWidth: 190,
        flex: 1,
        checked: true,
        label: "Shift Pattern (Schedule)",
        valueGetter: (params: any) => params?.row?.fullTimeShifts ?? "-",
      };

      const seniorityColumn = {
        field: "seniorityYears",
        headerName: "Seniority",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Seniority
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row.seniorityYears}</Box>;
        },
        valueGetter: (params: any) => params.row.seniorityYears,
        minWidth: 190,
        flex: 1,
        checked:
          shiftAllocationType === SENIORITY ||
          shiftAllocationType === WORKCENTER ||
          shiftAllocationType === LOCATION,
        label: "Seniority",
      };

      const reportingManager = {
        field: "reportingManager",
        headerName: "Reporting Manager",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Reporting Manager
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          let reportingManager = params.row.reportingManager ?? "-";
          if (reportingManager === "") {
            reportingManager = "-";
          }
          return (
            <Box
              pl={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                <Highlight value={reportingManager} searchText={search} />
              </Typography>
            </Box>
          );
        },
        valueGetter: (params: any) => params.row.reportingManager ?? "",
        minWidth: 180,
        flex: 1,
        checked: false,
        label: "Reporting Manager",
      };

      const statusColumn = {
        field: "Status",
        headerName: "Status",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Shift Status
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return (
            <ShiftStatus
              label={params?.row?.status}
              reason={params?.row?.reason}
            />
          );
        },
        valueGetter: (params: any) => params.row.status,
        minWidth: 150,
        flex: 1,
        checked: true,
        label: "Shift Status",
      };

      const overtimeColumn = {
        field: "overtime",
        headerName: "Overtime",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Overtime
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row?.overtime}</Box>;
        },
        valueGetter: (params: any) => params.row?.overtimeMins,
        minWidth: 150,
        flex: 1,
        checked:
          shiftAllocationType === WORKCENTER ||
          shiftAllocationType === LOCATION ||
          shiftAllocationType === PRIMARYJOB,
        label: "Overtime",
      };

      const workCenterColumn = {
        field: "workCenter",
        headerName: "Workcenter",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Workcenter
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params.row?.workCenter?.name ?? "-"}</Box>;
        },
        valueGetter: (params: any) => params.row?.workCenter?.name,
        minWidth: 160,
        flex: 1,
        checked:
          shiftAllocationType === WORKCENTER ||
          shiftAllocationType === LOCATION,
        label: "Workcenter",
      };

      const primaryJobNameColumn = {
        field: "primaryJobName",
        headerName: "Primary Job",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  // paddingLeft: "8px",
                }}
              >
                Primary Job
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <MultiLineTooltip text={params?.row?.primaryJobName ?? "-"} />;
        },
        valueGetter: (params: any) => params.row?.primaryJobName,
        minWidth: 160,
        flex: 1,
        checked: shiftAllocationType === PRIMARYJOB,
        label: "Primary Job",
      };

      const workerIdColumn = {
        field: "companyEmployeeId",
        headerName: "Worker ID",
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "rgba(0, 0, 0, 0.87)",
                  paddingLeft: "8px",
                }}
              >
                Worker ID
              </Typography>
            </Box>
          );
        },
        renderCell: (params: any) => {
          return <Box pl={1}>{params?.row?.companyEmployeeId}</Box>;
        },
        valueGetter: (params: any) => params?.row?.companyEmployeeId,
        minWidth: 160,
        flex: 1,
        checked: shiftAllocationType === PRIMARYJOB,
        label: "Worker ID",
        sortComparator: (v1: any, v2: any) => {
          const getNumericPart = (
            value: string | undefined | null
          ): number | null => {
            if (!value) return null;
            const trimmedValue = value.trim(); // Trim spaces
            const match = trimmedValue.match(/^\d+$/); // Match fully numeric values
            return match ? parseInt(trimmedValue, 10) : null;
          };

          const isBlank = (value: string | undefined | null) =>
            value === null || value === undefined || value.trim() === "";

          // Handle blank/null/undefined values
          const blank1 = isBlank(v1);
          const blank2 = isBlank(v2);

          if (blank1 && blank2) return 0; // Both are blank
          if (blank1) return 1; // Blank values should come last
          if (blank2) return -1; // Blank values should come first

          const num1 = getNumericPart(v1);
          const num2 = getNumericPart(v2);

          // First compare by numerical value
          if (num1 !== null && num2 !== null) {
            return num1 - num2;
          }

          // If one is numeric and the other is not, prioritize the numeric one
          if (num1 !== null) return -1;
          if (num2 !== null) return 1;

          // Fall back to standard string comparison
          return v1.localeCompare(v2);
        },
      };

      const columns = [
        workersColumn,
        statusColumn,
        workerTypeColumn,
        workCenterColumn,
        locationsColumn,
        shiftTimeColumn,
        overtimeColumn,
        seniorityColumn,
        reportingManager,
        primaryJobNameColumn,
        workerIdColumn,
      ];

      setColumns(columns);
    } catch (e) {
      console.log("Error setting up column headers", e);
    }
  }, [
    numberOfWorkers,
    search,
    shiftMetadata,
    activeStep,
    workerAvailableForAssign,
    shiftAllocationType,
    isOpenForOtherLocation,
  ]);
  return {
    columns,
    setColumns,
  };
};

export default useShiftDetailsDialogColumns;
